<template>
  <div class="mt-2">
    <div v-for="(discount, index) in data" :key="index">
      <span class="text-xs">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          v-tooltip="'El pedido tiene un descuento aplicado.'"
          class="w-3 h-3"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
          />
        </svg>
        <div>{{ discount.code }}</div>
        <div v-if="discount.description" class="text-xs italic">
          {{ discount.description }}
        </div>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: "discountDetail",
  props: {
    data: {
      type: [Array, Object],
      default: null,
    },
  },
};
</script>

<style lang="scss" scoped></style>
