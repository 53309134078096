<template>
  <div>
    <div class="mt-1" v-if="librosGoles">
      <div class="text-xs">
        Apuesta: <span class="font-bold">{{ librosGoles }}</span>
      </div>
    </div>
    <div v-if="detalle.apuestas.length > 0" class="mt-1">
      <div
        :key="i"
        v-for="(apuesta, i) in detalle.apuestas"
        class="text-xs"
        v-html="apuesta"
      ></div>
    </div>
    <div v-if="detalle.excentricidades.length > 0" class="mt-1">
      <div
        :key="i"
        v-for="(ex, i) in detalle.excentricidades"
        class="text-xs"
        v-html="ex.value"
        v-tooltip="'Apuesta: ' + ex.key"
      ></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    shipment: {
      type: Object,
      default: null,
    },
  },
  computed: {
    librosGoles() {
      if (!this.shipment) return { apuestas: [] };
      const tmp = this.shipment.cart
        .filter((it) => it && it.sku === "MUNDIAL")
        .map((it) => {
          if (it.apuesta.premios && it.apuesta.premios.producto_goles) {
            //   var items = [];
            // for (var i = 1; i < 7; i++) {
            //   items.push(i);
            // }

            return [
              it.apuesta.premios.producto_goles["1"].sku,
              it.apuesta.premios.producto_goles["2"].sku,
              it.apuesta.premios.producto_goles["3"].sku,
              it.apuesta.premios.producto_goles["4"].sku,
              it.apuesta.premios.producto_goles["5"].sku,
              it.apuesta.premios.producto_goles["6"].sku,
            ];
          }
        });

      console.log("librosGoles", tmp);

      // Flatten array
      const librosGoles = tmp
        .reduce((acc, it) => {
          return acc.concat(it);
        }, [])
        .join(", ");
      return librosGoles;
    },
    detalle() {
      if (!this.shipment) return { apuestas: [] };
      const tmp = this.shipment.cart
        .filter(
          (it) => it.sku === "APUESTA" && it.apuesta.premios.producto_resultado
        )
        .map((it) => {
          //   Si el dia del partido es hoy, se muestra "pendiente"
          const fecha = this.$moment(it.apuesta.partido.fechaPartido);
          const librosExtra = it.libros - 1;
          const apuesta = `${it.apuesta.partido.local} ${it.apuesta.resultado.local} - ${it.apuesta.resultado.visitante} ${it.apuesta.partido.visitante}`;

          let skus = [];
          if (it.apuesta.premios.producto_resultado)
            skus.push(it.apuesta.premios.producto_resultado.sku);
          if (it.apuesta.premios.producto_resultado_exacto)
            skus.push(it.apuesta.premios.producto_resultado_exacto.sku);

          return {
            skus,
            fecha,
            apuesta,
            librosExtra,
          };
        })
        .reduce(
          (acc, it) => {
            // Si la fecha es hoy o antes, se muestra "pendiente"
            let skus = [];

            if (
              it.fecha.isSameOrAfter(this.$moment(), "day") &&
              it.librosExtra === null
            ) {
              const skusStringPending = it.skus.join(",");
              acc.apuestas.push(
                `<span class="font-light">${it.apuesta}</span> -  Pendiente (${skusStringPending})`
              );
            } else {
              for (let i = 0; i < it.librosExtra; i++) {
                skus.push(it.skus[i]);
              }
              const skusString = skus.length > 0 ? `(${skus.join(",")})` : "";
              acc.apuestas.push(
                `<span class="font-medium">${it.apuesta}</span> -  ${
                  it.librosExtra || 0
                } Libro${it.librosExtra > 1 ? "s" : ""} extra ${skusString}`
              );
            }

            acc.librosExtra += it.librosExtra;
            return acc;
          },
          { apuestas: [], librosExtra: 0 }
        );

      tmp.excentricidades = this.shipment.cart
        .filter(
          (it) =>
            it.sku === "MUNDIAL" &&
            it.apuesta.excentricidades &&
            Object.keys(it.apuesta.excentricidades).length > 0
        )
        .reduce((total, it) => {
          const excen = it.apuesta.excentricidades;
          // console.log("excentricidades", excen);
          total = [
            {
              key: "Bono Peretti",
              value:
                "<strong>BP</strong>: " + excen.apuestas_excentrica_peretti,
            },
            {
              key: "Suscrip. 2023",
              value:
                "<strong>SU</strong>: " + excen.apuestas_excentrica_suscripcion,
            },
          ];
          return total;
        }, {});

      console.log("excentricidades", tmp.excentricidades);

      return tmp;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>