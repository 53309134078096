<template>
  <div class="mb-2">
    <label
    v-if="false"
      for="input"
      class="block mb-1 text-sm font-medium leading-5 text-gray-700"
      >Métodos de Envío</label
    >
    <div v-if="shippingMethods && shippingMethods.length > 0">
      <multiselect
        ref="shippingMethods"
        label="label"
        track-by="key"
        key="shippingMethods"
        v-model="selected"
        :multiple="true"
        :options="shippingMethods"
        placeholder="País"
        selected-label=""
        select-label=""
        deselect-label=""
      >
        <template slot="singleLabel" slot-scope="{ option }">
              <div v-if="option" class="flex items-start space-x-1 text-sm">
              <div class="block font-normal truncate">
                {{ option.label }}
              </div>
              <div v-if="option.sublabel" class="block text-xs truncate">
                {{ option.sublabel }}
              </div>
          </div>
        </template>
        <template slot="option" slot-scope="{ option }">
          <div v-if="option" class="flex flex-col items-start space-x-1 text-sm">
              <div class="block font-normal truncate">
                {{ option.label }}
              </div>
              <div v-if="option.sublabel" class="block text-xs truncate">
                {{ option.sublabel }}
              </div>
          </div>
        </template>
        <template slot="placeholder">
          <div class="text-xs">Seleccionar Métodos</div>
        </template>
      </multiselect>
    </div>
    <div class="text-xs text-gray-600">{{ description }}</div>
  </div>
</template>

<script>
import { makeFindMixin } from "feathers-vuex";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
export default {
  components: {
    Multiselect,
  },
  props: {
    value: {
      type: [Array, String],
      default: null,
    },
    initial: {
      type: [String, Array],
      default: null,
    },
    label: {
      type: String,
      default: "Paises",
    },
    description: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      countries: [],
      selected: null,
      detected: null,
    };
  },
  mixins: [makeFindMixin({ service: "shipping-zone" })], // Step 2
  computed: {
    shippingZoneParams() {
      return {
        query: {
          $sort: {
            name: -1,
          },
        },
      };
    },
    shippingMethods(){
      return  this.shippingZone.map(sz => {
      return {
          label: sz.company,
          sublabel: sz.name,
          key: sz._id,
          query: { shippingMethodId: sz._id }
        };
    })
    }
  },
  watch: {
    selected(newValue) {
      if (newValue) {
        this.$emit(
          "input",
          newValue.map((v) => v.key)
        );
      }
    },
  },
  mounted() {},
};
</script>

<style lang="css">
.multiselect__input::placeholder {
  @apply text-sm;
}
</style>
