<template>
  <div>
    <w-top-bar>Envíos Pendientes</w-top-bar>
    <w-andreani-confirm
      v-if="showAndreaniConfirm"
      @hide="showAndreaniConfirm = false"
      :ids="shipmentsIdsToGenerate"
    />
    <div class="h-full p-5 bg-gray-200">
      <div class="grid grid-cols-2 justify-items-stretch">
        <div class="flex gap-2 justify-self-start">
          <w-shipping-method-select
            label="Mostrar"
            emptyLabel="Métodos de envio"
            class="z-20 md:w-48"
            v-model="filterShipping"
          />
          <!-- <w-select label="Mostrar" emptyLabel="Métodos de envio" class="z-20 md:w-48" v-model="filterShipping" :options="shippingMethods" /> -->
          <w-select
            label="Mostrar"
            emptyLabel="Filtros"
            class="z-20 md:w-48"
            v-model="filter"
            :options="filterTypes"
          />
        </div>
        <div class="justify-self-end">
          <w-date-range v-model="dateFilter" @change="reload()" />
        </div>
      </div>
      <div class="grid grid-cols-2 mb-3 justify-items-stretch">
        <div class="flex justify-self-start">
          <input
            class="relative w-48 py-2 pl-3 pr-10 text-left transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md cursor-default focus:outline-none focus:ring-blue focus:border-blue-300 sm:text-sm sm:leading-5"
            type="text"
            v-model="skuFilter"
            :debounce="1000"
            name="search"
            placeholder="SKUs"
          />
          <div class="flex items-center">
            <input
              class="relative mr-2 w-48 py-2 pl-3 ml-2 pr-10 text-left transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md cursor-default focus:outline-none focus:ring-blue focus:border-blue-300 sm:text-sm sm:leading-5"
              type="text"
              v-model="groupFilter"
              :debounce="1000"
              name="group"
              placeholder="Grupo"
            />
            <svg
              @click="loadMore()"
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 text-indigo-600 hover:text-indigo-800 cursor-pointer"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              />
            </svg>
          </div>
        </div>
        <div class="justify-self-end">
          <div class="mt-1">
            <div class="flex gap-2">
              <button
                @click="dowloadCsv()"
                class="relative flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <span class="absolute inset-y-0 right-0 flex items-center pr-3">
                  <svg
                    v-if="downloading"
                    class="w-5 h-5 mr-1 text-indigo-500 animate-spin group-hover:text-indigo-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <circle
                      class="opacity-25"
                      cx="12"
                      cy="12"
                      r="10"
                      stroke="currentColor"
                      stroke-width="4"
                    ></circle>
                    <path
                      class="opacity-75"
                      fill="currentColor"
                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                    ></path>
                  </svg>

                  <svg
                    v-else
                    class="w-5 h-5 mr-1 text-indigo-500 group-hover:text-indigo-400"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                    />
                  </svg>
                </span>
                <span class="mr-6">CSV</span>
                <div
                  class="absolute top-0 right-0 px-2 py-1 -mt-2 -mr-2 text-xs bg-red-500 rounded-full"
                >
                  {{ totalResults }}
                </div>
              </button>

              <descargar-reparto :query="filtroEtiquetas" />
              <descargar-andreani :query="filtroEtiquetas" />
              <descargar-dhl :query="filtroEtiquetas" />
            </div>
          </div>
        </div>
      </div>

      <div class="relative">
        <w-loading class="absolute inset-center-mx mt-10" v-if="loading"></w-loading>
        <w-shipments
          :class="{ 'opacity-25': loading }"
          v-model="status"
          @archive="doArchive"
          @andreani="doAndreani"
          @downloadEtiqueta="downloadEtiqueta"
          :totalResults="totalResults"
          :patch-pending="false"
          :find-pending="false"
          @nextPage="nextPage()"
          :loading="loading"
          :items="shipments"
        />
        <w-pagination
          v-if="totalResults > 0"
          @pageChanged="currentPage = $event"
          :totalResults="totalResults"
          v-model="currentPage"
          :resultsPerPage="limit"
        />
      </div>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
import { makeFindMixin } from "feathers-vuex";
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment-timezone";
moment.tz.setDefault("America/Argentina/Buenos_Aires");
// Setear moment a gmt-3

import DescargarDhl from "@/components/etiquetas/DescargarDhl";
import DescargarAndreani from "@/components/etiquetas/DescargarAndreani";
import DescargarReparto from "@/components/etiquetas/DescargarReparto";
const FORCE_LIVE = false;

const apiURL =
  process.env.NODE_ENV === "production" || FORCE_LIVE
    ? "https://api.orsai.org/"
    : "http://localhost:3030/";

export default {
  name: "shipmentsList",
  components: {
    DescargarDhl,
    DescargarAndreani,
    DescargarReparto,
  },
  data() {
    return {
      showAndreaniConfirm: false,
      shipmentsIdsToGenerate: null,

      shipments: [],
      skuFilterTerm: null,
      groupFilterTerm: null,
      filter: "default",
      filterShipping: null,
      filterTypes: [
        {
          label: "Pendientes",
          key: "default",
          query: { archive: { $ne: true }, prelaunch: { $ne: true } },
        },
        { label: "Archivo", key: "archive", query: { archive: true } },
        {
          label: "Prelanzamiento",
          key: "prelaunch",
          query: { prelaunch: true, archive: { $ne: true } },
        },
        {
          label: "Prelanzamiento (Sin etiqueta)",
          key: "prelaunch-no-label",
          query: {
            prelaunch: true,
            archive: { $ne: true },
            trackingCode: null,
          },
        },
        {
          label: "Etiqueta impresa",
          key: "printed",
          query: { printed: true },
        },
        {
          label: "Etiqueta sin imprimir",
          key: "notprinted",
          query: { printed: { $ne: true }, isAndreani: true },
        },
        { label: "Todos", key: "all", query: {} },
      ],
      shippingMethods: [],
      status: null,
      dateFilter: {
        start: moment().subtract(0, "days").startOf("day").toDate(),
        end: moment().subtract(0, "days").endOf("day").toDate(),
      },
      downloading: false,
      downloadingAndreani: false,
      downloadingDHL: false,
      loading: false,
      archive: false,
      currentPage: 1,
      limit: 20,
      skip: 0,
      totalResults: 0,
      filtrarAndreani: false,
    };
  },
  mixins: [
    // makeFindMixin({ service: "shipments", qid: "shipments", name: "shipments" }),
    makeFindMixin({
      service: "shipments",
      name: "andreani",
      items: "andreani",
      qid: "andreani",
    }),
    makeFindMixin({
      service: "shipments",
      name: "dhl",
      items: "dhl",
      qid: "dhl",
    }),
  ],
  watch: {
    query: {
      deep: true,
      handler() {
        console.log("query Changed");
        this.currentPage = 1;
        this.loadMore();
      },
    },
    isArchive() {
      // this.findShipments();
      this.findAndreani();
    },
    currentPage() {
      this.loadMore();
    },
    skuFilter() {
      this.currentPage = 1;
      this.findAndreani();
    },
    groupFilter() {
      this.currentPage = 1;
      this.findAndreani();
    },
    filter() {
      this.currentPage = 1;
      this.updateFilter();
    },
    filterShipping() {
      this.currentPage = 1;
      this.updateFilter();
    },
    dateFilter() {
      this.currentPage = 1;
      this.clearAll();
      // this.findShipments();
      this.findAndreani();
    },
  },
  computed: {
    filtroEtiquetas() {
      const query = {};
      query.createdAt = {
        $gt: moment(this.dateFilter.start).unix(),
        $lt: moment(this.dateFilter.end).unix(),
      };
      if (this.groupFilter) {
        query.groupName = this.groupFilter;
      }

      if (this.skuFilter) {
        console.log("adding sku to query", this.skuFilter);
        // query["items.sku"] = { $in: this.skuFilter.split(",") };
        query["items.sku"] = this.skuFilter;
      }

      return query;
    },
    skuFilter: {
      get() {
        // return this.skuFilterTerm;
        return this.$route.query.sku;
      },
      set: _.debounce(function (val) {
        console.log("changing skufilter");
        if (!val) {
          this.$router.push({ query: {} });
        }
        if (this.$route.query.q !== val) {
          this.$router.push({ query: { sku: val } });
        }
        // if (this.skuFilterTerm !== val) this.skuFilterTerm = val;
      }, 1000),
    },
    groupFilter: {
      get() {
        return this.$route.query.group;
      },
      set: _.debounce(function (val) {
        console.log("changing groupFilter");
        if (!val) {
          this.$router.push({ query: {} });
          return;
        }
        if (this.groupFilterTerm !== val) {
          this.$router.push({ query: { group: val } });
          return;
        }
      }, 1000),
    },
    totalResultsAndreani() {
      // console.log("this.dhlPaginationData", this.andreaniPaginationData);
      if (this.andreaniPaginationData.andreani)
        return this.andreaniPaginationData.andreani.mostRecent.total;
      return 0;
    },
    totalResultsDhl() {
      console.log("this.dhlPaginationData", this.dhlPaginationData);
      if (this.dhlPaginationData.dhl) return this.dhlPaginationData.dhl.mostRecent.total;
      return 0;
    },
    ...mapGetters("auth", ["user"]),
    isArchive() {
      return this.archive || this.$route.query.archive;
    },
    query() {
      console.log("Generating Query", this.filter, this.filterShipping);

      if (this.$route.query.andreani) {
        console.log("Filtro Andreani");
        return {
          lastStatus: this.$route.query.andreani,
          createdAt: {
            $gt: moment("2022-12-01T03:00:00.000Z").unix(),
          },
          shippingMethodId: "61956f84f0ab2b002e496733",
          $limit: 10000,
        };
      }
      if (this.$route.query.dhl) {
        return {
          lastStatus: this.$route.query.dhl,
          createdAt: {
            $gt: moment("2022-12-01T03:00:00.000Z").unix(),
          },
          shippingMethodId: {
            $in: [
              "5fb9b232a88f950f6fe19f0f",
              "5fb9b232a88f950f6fe19f11",
              "5fb9b232a88f950f6fe19f0e",
              "5fb9b232a88f950f6fe19f10",
            ],
          },
          $limit: 10000,
        };
      }

      if (this.$route.query.orderId) {
        return {
          orderId: this.$route.query.orderId,
        };
      }

      let extraQuery = {};
      if (this.filter) {
        const queryFound = this.filterTypes.find((el) => el.key === this.filter);
        console.log("queryFound", queryFound);
        if (queryFound) {
          extraQuery = queryFound.query;
        }
      }

      console.log("this.dateFilter.start", this.dateFilter.start);
      console.log("this.dateFilter.end", this.dateFilter.end);

      let query = {
        $limit: this.limit,
        ...extraQuery,
        createdAt: {
          $gt: moment(this.dateFilter.start).unix(),
          $lt: moment(this.dateFilter.end).unix(),
        },
        $select: [
          "createdAt",
          "createdAtOriginal",
          "hasBonos",
          "updatedAt",
          "items.sku",
          "items.name",
          "items.qty",
          // "items.apuesta.resultado",
          // "items.apuesta.partido",
          // "items.apuesta.premios.sku",
          // "items",
          "order.cart.sku",
          "order.cart.name",
          "order.cart.qty",
          "order.cart.libros",
          "order.cart.apuesta.partido",
          "order.cart.apuesta.resultado",
          "order.cart.apuesta.premios.producto",
          "orderId",
          "prelaunch",
          "shippingId",
          "shipping",
          "shippingMethod",
          "shippingMethodId",
          "trackingCode",
          "dhlAWB",
          "events",
          "printed",
          "isAndreani",
          "status",
          "lastStatus",
          "delivered",
          "deliveredData",
        ],
      };

      if (this.skuFilter) {
        let skus = this.skuFilter.toUpperCase().split(",");
        query["items.sku"] = { $in: skus };
      }

      // if (this.groupFilter) {
      //   // query["groupName"] = this.groupFilter;
      //   return {
      //     groupName: this.groupFilter,
      //   };
      // }

      if (this.groupFilter) {
        query.groupName = this.groupFilter;
      }

      if (this.filterShipping) {
        if (this.filterShipping && this.filterShipping.length > 0)
          query["shippingMethodId"] = { $in: this.filterShipping };
        else delete query["shippingMethodId"];
      }

      console.log("query andreani", query);

      return query;
    },
    countParams() {
      return {
        query: { ...this.query, $limit: 0, $sort: { createdAt: -1 } },
      };
    },
    shipmentsParams() {
      console.log("this.$route.query", this.$route.query);

      return {
        query: this.query,
        $limit: 1,
      };
    },
    andreaniParams() {
      if (this.$route.query.andreani) {
        return {
          lastStatus: this.$route.query.andreani,
          createdAt: {
            $gt: moment("2022-12-01T03:00:00.000Z").unix(),
          },
          shippingMethodId: "61956f84f0ab2b002e496733",
          $limit: 10000,
        };
      }
      return {
        query: {
          ...this.query,
          // isAndreani: true,
          $or: [{ trackingCode: { $ne: null } }, { isAndreani: true }],
        },
        $select: ["orderId", "orderIdCode", "isAndreani"],
        $limit: 0,
      };
    },
    dhlParams() {
      if (this.$route.query.dhl) {
        return {
          lastStatus: this.$route.query.dhl,
          createdAt: {
            $gt: moment("2022-12-01T03:00:00.000Z").unix(),
          },
          $or: [
            { shippingMethodId: "5fb9b232a88f950f6fe19f0f" }, // DHL Internacional
            { shippingMethodId: "5fb9b232a88f950f6fe19f0f" }, // DHL Internacional
            { shippingMethodId: "5fb9b232a88f950f6fe19f11" }, // DHL Internacional
            { shippingMethodId: "5fb9b232a88f950f6fe19f0e" }, // DHL Internacional
            { shippingMethodId: "5fb9b232a88f950f6fe19f10" }, // DHL Internacional
          ],
          $limit: 10000,
        };
      }
      return {
        query: {
          // ...this.query,
          // trackingCode: { $ne: null },
          tipoEtiqueta: "dhl",
        },
        $select: ["orderId", "orderIdCode"],
        $limit: 0,
      };
    },
  },
  async created() {
    if (this.$route.query.q && this.$route.query.q.length > 1) {
      this.filter = this.$route.query.q;
    }

    if (this.$route.query.szId && this.$route.query.szId.length > 1) {
      this.filterShipping = this.$route.query.szId;
    }

    if (this.$route.query.group && this.$route.query.group.length > 1) {
      this.groupFilter = this.$route.query.group;
    }

    if (this.$route.query.andreani) {
      this.filtrarAndreani = this.$route.query.andreani;
    }

    const shippingMethods = await this.findShippingMethods();
    this.shippingMethods = shippingMethods.data.map((sz) => {
      return {
        label: sz.company,
        sublabel: sz.name,
        key: sz._id,
        query: { shippingMethodId: sz._id },
      };
    });
  },
  async mounted() {
    this.loadMore();
  },
  destroyed() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    async loadMore() {
      this.findAndreani();
      this.loading = true;
      const result = await this.findShipments({
        query: {
          ...this.query,
          $limit: this.limit,
          $skip: this.limit * (this.currentPage - 1),
        },
      });
      this.shipments = result.data;
      this.totalResults = result.total;
      this.loading = false;
    },
    ...mapActions("shipments", { patch: "patch", findShipments: "find" }),
    ...mapActions("shipping-zone", { findShippingMethods: "find" }),
    ...mapMutations("shipments", ["clearAll"]),
    updateFilter() {
      if (this.filter || this.filterShipping) {
        this.$router.push({
          path: "shipments",
          query: { q: this.filter, szId: this.filterShipping },
        });
        this.clearAll();
        this.findShipments();
      }
    },
    objectToFormData(obj, form, namespace) {
      var fd = form || {};
      var formKey;

      for (var property in obj) {
        if (namespace) {
          formKey = namespace + "[" + property + "]";
        } else {
          formKey = property;
        }

        // if the property is an object, but not a File,
        // use recursivity.
        if (typeof obj[property] === "object") {
          this.objectToFormData(obj[property], fd, property);
        } else {
          // if it's a string or a File object
          // fd.push(`${formKey}=${obj[property]}`);
          fd[formKey] = obj[property];
        }
      }

      return fd;
    },
    async doArchive(data) {
      console.log("doArchive", data);

      let query = null;
      if (data.selected) query = { _id: { $in: data.selected } };
      if (data.all) {
        query = {
          createdAt: {
            $gt: moment(this.dateFilter.start).unix(),
            $lt: moment(this.dateFilter.end).unix(),
          },
        };
      }
      if (query) {
        console.log(query);
        // await this.patch([null, { archiveTest: true }, { query }]);
        await this.$store.dispatch("shipments/patch", [
          null,
          { archive: true, archived: new Date() },
          { query },
        ]);
        console.log("READY");
        this.reload();
      }
    },
    async doAndreani(data) {
      this.shipmentsIdsToGenerate = null;
      this.showAndreaniConfirm = true;
      let query = null;
      if (data.all) {
        // Buscamos los IDs por la fecha
        const toGenerate = await this.findShipments({
          query: {
            createdAt: {
              $gt: moment(this.dateFilter.start).unix(),
              $lt: moment(this.dateFilter.end).unix(),
            },
            pendingAndreani: true,

            // isAndreani: true
          },
          $select: ["orderId", "orderIdCode", "isAndreani"],
        });
        this.shipmentsIdsToGenerate = toGenerate.data;

        // query = {
        //   createdAt: {
        //     $gt: moment(this.dateFilter.start).unix(),
        //     $lt: moment(this.dateFilter.end).unix(),
        //   },
      } else if (data.selected) {
        // Tenemos los ID generamos las etiquetas
        query = { _id: { $in: data.selected } };
        console.log(query);
        this.shipmentsIdsToGenerate = data.selected;
      }

      // if (query) {
      //   console.log("Andreani",query);
      //   // await this.$store.dispatch("andreani/patch", [
      //   //   null,
      //   //   { archive: true, archived: new Date() },
      //   //   { query },
      //   // ]);
      //   console.log("READY");
      // }
    },
    nextPage() {
      console.log("nextPage");
      this.skip += 20;
      this.reload();
    },
    reload() {
      console.log("reload");
      this.status = "clear";
      this.findShipments();
    },
    print() {
      window.print();
    },
    async dowloadCsv() {
      console.log("dowloadCsv");
      console.log("download");

      let fileName = [
        moment(this.dateFilter.start).format("DD-MM-YY"),
        moment(this.dateFilter.end).format("DD-MM-YY"),
      ];

      if (fileName[0] == fileName[1]) fileName = fileName[0];
      else fileName = fileName.join(" - ");

      this.downloading = true;
      // eslint-disable-next-line no-unused-vars
      let extraQuery = {};
      const found = this.filterTypes.find((el) => el.key === this.filter);
      console.log("found", found);
      console.log("found", found);
      let tmp = found.query;

      if (this.skuFilter) {
        let skus = this.skuFilter.toUpperCase().split(",");
        extraQuery["items.sku"] = { $in: skus };
      }

      let date = {
        createdAt: {
          $gt: moment(this.dateFilter.start).toDate(),
          $lt: moment(this.dateFilter.end).toDate(),
        },
      };

      if (this.$route.query.orderId) {
        extraQuery = {
          orderId: this.$route.query.orderId,
        };
        tmp = {};
        date = {};
      }

      if (this.filterShipping) {
        if (this.filterShipping && this.filterShipping.length > 0)
          extraQuery["shippingMethodId"] = { $in: this.filterShipping };
        else delete extraQuery["shippingMethodId"];
      }

      // eslint-disable-next-line no-unused-vars
      let params = {
        ...tmp,
        // $limit: "-1",
        ...extraQuery,
        ...date,
      };

      if (this.groupFilter) {
        params.groupName = this.groupFilter;
      }

      // Si es andreani, generar su query
      if (this.$route.query.andreani) {
        console.log("Filtro Andreani");
        params = {
          lastStatus: this.$route.query.andreani,
          createdAt: {
            $gt: moment("2022-12-01T03:00:00.000Z").unix(),
          },
          shippingMethodId: "61956f84f0ab2b002e496733",
          $limit: 10000,
        };
      }

      if (this.$route.query.dhl) {
        params = {
          lastStatus: this.$route.query.dhl,
          createdAt: {
            $gt: moment("2022-12-01T03:00:00.000Z").unix(),
          },
          $or: [
            { shippingMethodId: "5fb9b232a88f950f6fe19f0f" }, // DHL Internacional
            { shippingMethodId: "5fb9b232a88f950f6fe19f0f" }, // DHL Internacional
            { shippingMethodId: "5fb9b232a88f950f6fe19f11" }, // DHL Internacional
            { shippingMethodId: "5fb9b232a88f950f6fe19f0e" }, // DHL Internacional
            { shippingMethodId: "5fb9b232a88f950f6fe19f10" }, // DHL Internacional
          ],
          $limit: 10000,
        };
      }

      console.log("PARAMS DOWNLOAD", params);

      // this.axios
      //   .post(`${apiURL}download-csv`, {
      //     responseType: "blob",
      //     params,
      //   })

      this.axios({
        method: "post",
        url: `${apiURL}download-csv`,
        data: {
          query: params,
        },
      })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", `Envios Tienda Hernan Casciari - ${fileName}.csv`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.downloading = false;
        })
        .catch(() => {
          this.downloading = false;
        });
    },
    async download() {
      console.log("download");

      let fileName = [
        moment(this.dateFilter.start).format("DD-MM-YY"),
        moment(this.dateFilter.end).format("DD-MM-YY"),
      ];

      if (fileName[0] == fileName[1]) fileName = fileName[0];
      else fileName = fileName.join(" - ");

      this.downloading = true;
      // let extraQuery = {};
      // if (this.filter === "archive") {
      //   extraQuery["archive"] = true;
      // }
      let extraQuery = {};
      let tmp = (this.filterTypes.find((el) => el.key === this.filter) || {}).query;

      if (this.skuFilter) {
        let skus = this.skuFilter.toUpperCase().split(",");
        extraQuery["items.sku"] = { $in: skus };
      }

      if (this.filterShipping) {
        extraQuery["shippingMethodId"] = this.filterShipping;
      }

      console.log("filterShipping", this.filterShipping);

      let params = this.objectToFormData({
        ...tmp,
        $limit: "-1",
        ...extraQuery,
        createdAt: {
          $gt: moment(this.dateFilter.start).unix(),
          $lt: moment(this.dateFilter.end).unix(),
        },
      });

      console.log("QUERY STRING", params);
      console.log("extraQuery", extraQuery);

      this.axios
        .get(`${apiURL}shipments`, {
          responseType: "blob",
          params,
        })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", `Envios Tienda Hernan Casciari - ${fileName}.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.downloading = false;
        })
        .catch(() => {
          this.downloading = false;
        });
    },
    async downloadEtiqueta(shipmentId, name = "Andreani") {
      console.log("downloadEtiqueta", name);
      let params = {
        shipments: [shipmentId],
      };

      if (name === "Andreani") {
        this.axios
          .post(
            `${apiURL}andreani-etiquetas`,
            { params },
            {
              responseType: "arraybuffer",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/pdf",
              },
            }
          )
          .then((response) => {
            console.log("response.data", response.data);
            let blob = new Blob([response.data], { type: "application/pdf" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = `${name}-${shipmentId}.pdf`;
            link.click();
            this.downloadingAndreani = false;
          })
          .catch(() => {
            this.downloadingAndreani = false;
          });
      }
      if (name === "DHL") {
        console.log("IS DHL");
        this.axios
          .get(
            `${apiURL}descargar-dhl`,
            { params },
            {
              responseType: "arraybuffer",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/pdf",
              },
            }
          )
          .then((response) => {
            let blob = new Blob([response.data], { type: "application/pdf" });
            let link = document.createElement("a");
            link.href = window.URL.createObjectURL(blob);
            link.download = `${name}-${shipmentId}.pdf`;
            link.click();
            this.downloadingAndreani = false;
          })
          .catch(() => {
            this.downloadingAndreani = false;
          });
      }
    },
    async downloadAndreani() {
      console.log("downloadAndreani");

      let fileName = [
        moment(this.dateFilter.start).format("DD-MM-YY"),
        moment(this.dateFilter.end).format("DD-MM-YY"),
      ];

      if (fileName[0] == fileName[1]) fileName = fileName[0];
      else fileName = fileName.join(" - ");

      this.downloadingAndreani = true;

      let extraQuery = {};
      let tmp = (this.filterTypes.find((el) => el.key === this.filter) || {}).query;

      if (this.skuFilter) {
        let skus = this.skuFilter.toUpperCase().split(",");
        extraQuery["items.sku"] = { $in: skus };
      }

      if (this.filterShipping) {
        extraQuery["shippingMethodId"] = this.filterShipping;
      }

      console.log("filterShipping", this.filterShipping);

      let params = {
        ...tmp,
        ...extraQuery,
        createdAt: {
          $gt: moment(this.dateFilter.start).startOf("day").unix(),
          $lt: moment(this.dateFilter.end).unix(),
        },
      };

      // if (this.groupFilter) {
      //   params = {
      //     groupName: this.groupFilter,
      //   };
      // }
      if (this.groupFilter) {
        params.groupName = this.groupFilter;
      }

      console.log("QUERY STRING", params);
      console.log("extraQuery", extraQuery);

      this.axios
        .post(
          `${apiURL}andreani-etiquetas`,
          { params },
          {
            responseType: "arraybuffer",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/pdf",
            },
          }
        )
        .then((response) => {
          console.log("response.data", response.data);
          let blob = new Blob([response.data], { type: "application/pdf" });
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = `Andreani-${fileName}.pdf`;
          link.click();
          this.downloadingAndreani = false;
        })
        .catch(() => {
          this.downloadingAndreani = false;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.inset-center-mx {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0%);
}
</style>
