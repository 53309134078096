<template>
  <div>
    <button
      v-if="total > 0"
      @click="downloadReparto()"
      class="relative flex justify-center px-4 py-2 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md group hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
    >
      <span class="absolute inset-y-0 right-0 flex items-center pr-3">
        <svg
          v-if="downloading"
          class="w-5 h-5 mr-1 text-indigo-500 animate-spin group-hover:text-indigo-400"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            class="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            stroke-width="4"
          ></circle>
          <path
            class="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>

        <svg
          v-else
          class="w-5 h-5 mr-1 text-indigo-500 group-hover:text-indigo-400"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
          />
        </svg>
      </span>
      <span class="mr-6">Reparto</span>
      <div
        class="absolute top-0 right-0 px-2 py-1 -mt-2 -mr-2 text-xs bg-red-500 rounded-full"
      >
        {{ total }}
      </div>
    </button>
  </div>
</template>

<script>
import { mapActions } from "vuex";
const FORCE_LIVE = false;
const apiURL =
  process.env.NODE_ENV === "production" || FORCE_LIVE
    ? "https://api.orsai.org/"
    : "http://localhost:3030/";
export default {
  name: "DescargarDHL",
  props: {
    query: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      total: 0,
      downloading: false,
    };
  },
  created() {
    this.calcularTotal();
  },
  watch: {
    query() {
      this.calcularTotal();
    },
  },
  methods: {
    ...mapActions("shipments", ["find"]),
    async calcularTotal() {
      console.log("etiquetas: ", this.query);

      let total = await this.find({
        query: {
          ...this.query,
          shippingMethodId: "5fb9b232a88f950f6fe19f0b",
          prelaunch: { $ne: true },
          $limit: 0,
        },
      });
      console.log("etiquetas: total", total);
      this.total = total.total;
    },
    async downloadReparto() {
      this.downloading = true;

      this.axios({
        method: "get",
        url: `${apiURL}descargar-reparto/reparto`,
        params: {
          ...this.query,
          shippingMethodId: "5fb9b232a88f950f6fe19f0b",
        },
        responseType: "blob",
      })
        .then((response) => {
          var fileURL = window.URL.createObjectURL(new Blob([response.data]));
          var fileLink = document.createElement("a");

          fileLink.href = fileURL;
          fileLink.setAttribute("download", `Reparto Tienda.pdf`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.downloading = false;
        })
        .catch(() => {
          this.downloading = false;
        });
    },
  },
};
</script>

<style>
</style>