<template>
  <div>
    <div v-if="bonos.length > 0" class="mt-2">
      <div class="font-medium">Bonos</div>
      <div v-for="(item, index) in bonos" :key="index">
        <div v-tooltip="item.label">{{ item.qty }} x {{ item.label }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
export default {
  props: {
    value: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      bonos: [],
    };
  },
  computed: {},
  async mounted() {
    const result = await this.find({
      query: {
        shipmentId: this.value,
      },
    });
    this.bonos = result.data.map((item) => {
      return {
        qty: item.bonos,
        label: [item.nombre, item.apellido].join(" "),
      };
    });
  },
  methods: {
    ...mapActions("bonos-canelones", ["find"]),
  },
};
</script>

<style lang="scss" scoped></style>
