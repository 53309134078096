<template>
  <div v-if="sku" class="flex">
      {{qty}} x {{sku}}
  </div>
</template>

<script>
import { makeFindMixin } from "feathers-vuex";
export default {
  props: {
    item: {
      type: [Object],
      default: null,
    },
  },
  mixins: [makeFindMixin({ service: "products" })],
  computed: {
      sku(){
        let prod = this.products.find((e) => {
          return e.name.includes(this.item.name);
        });
        if (prod) {
          console.log(this.item.name, prod);
          return prod.sku;
        }
        return null;
      },
      qty(){
          return this.item.qty || 1
      },
    productsParams() {
      return {
        query: {
          $select: ["name", "sku", "_id"],
        },
      };
    },
    variant() {
      return this.item.variant;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>