<template>
  <div class="flex flex-col" :class="{ 'opacity-50': loading }">
    <div v-if="selected.length > 0 || selectAllAll" class="py-2 text-sm text-center">
      <div
        class="flex items-center p-2 leading-none text-indigo-500 bg-gray-300 lg:rounded"
        role="alert"
      >
        <span class="flex-auto mx-2 text-left">
          Se han seleccionado
          {{ selectAllAll ? totalResults : selected.length }} envios de esta página.
        </span>
        <button
          @click="selectAllAll = true"
          v-if="!selectAllAll"
          class="flex px-2 py-1 mr-3 text-xs font-semibold uppercase bg-gray-400 rounded"
        >
          Seleccionar {{ totalResults }} envíos
        </button>
        <button
          @click="
            () => {
              selectAll = false;
              selectAllAll = false;
              selected = [];
            }
          "
          v-else
          class="flex px-2 py-1 mr-3 text-xs font-semibold uppercase bg-gray-400 rounded"
        >
          Anular la selección
        </button>
        <button
          @click="$emit('archive', { selected, all: selectAllAll })"
          class="flex justify-center px-2 py-1 mr-3 text-xs font-semibold text-green-600 uppercase bg-gray-400 rounded button-w-action"
        >
          <div class="" v-if="patchPending">
            <svg
              class="w-3 h-3 mr-1 animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
          <span v-else>Archivar {{ selectAllAll ? totalResults : selected.length }} envíos</span>
        </button>
        <button
          @click="$emit('andreani', { selected, all: selectAllAll })"
          class="flex justify-center px-2 py-1 mr-3 text-xs font-semibold text-white uppercase bg-orange-600 rounded button-w-action"
        >
          <div class="" v-if="patchPending">
            <svg
              class="w-3 h-3 mr-1 animate-spin"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
            >
              <circle
                class="opacity-25"
                cx="12"
                cy="12"
                r="10"
                stroke="currentColor"
                stroke-width="4"
              ></circle>
              <path
                class="opacity-75"
                fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
              ></path>
            </svg>
          </div>
          <span v-else
            >Generar Etiquetas Andreani
            {{ selectAllAll ? totalResults : selected.length }} envíos</span
          >
        </button>
      </div>
    </div>
    <div class="-my-2 overflow-x-auto md:overflow-hidden sm:-mx-6 lg:-mx-8">
      <div class="inline-block min-w-full py-2 overflow-hidden align-middle sm:px-6 lg:px-8">
        <div
          class="flex flex-col items-center justify-center h-48"
          v-if="items.length === 0 && !loading"
        >
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              class="w-12 h-12 text-base-300"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </div>
          <div class="my-2 font-normal text-gray-700">No hay pedidos</div>
        </div>
        <div v-else class="overflow-hidden shadow bshipment-b bshipment-gray-200 sm:rounded-lg">
          <table class="min-w-full overflow-hidden divide-y divide-gray-200 rounded-lg">
            <thead class="hidden md:table-header-group print:table-header-group">
              <tr>
                <th>
                  <div class="flex items-center justify-center w-10">
                    <input
                      id="select_all"
                      type="checkbox"
                      v-model="selectAll"
                      checked
                      @change="toggleAll"
                      class="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                    />
                  </div>
                </th>
                <th
                  class="py-3 pr-6 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase print:hidden md:table-cell bg-gray-50"
                >
                  Nombre
                </th>
                <th
                  v-if="hide.indexOf('event') == -1"
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase md:w-32 print:hidden md:table-cell bg-gray-50"
                >
                  Pedido
                </th>
                <th
                  v-if="hide.indexOf('event') == -1"
                  class="px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase print:hidden bg-gray-50"
                >
                  Detalles
                </th>
                <th
                  v-if="hide.indexOf('event') == -1"
                  class="w-32 px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase print:hidden bg-gray-50"
                >
                  Pago
                </th>
                <!-- <th
                  class="hidden px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase md:table-cell bg-gray-50"
                >
                  Fecha
                </th> -->
                <th
                  class="hidden px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase print:hidden md:table-cell bg-gray-50"
                ></th>
                <th
                  class="py-3 text-xs font-medium leading-4 tracking-wider text-right text-gray-500 uppercase print:hidden sm:hidden bg-gray-50"
                >
                  Procesado
                </th>
                <!-- <th
                  class="hidden px-6 py-3 text-xs font-medium leading-4 tracking-wider text-left text-gray-500 uppercase md:table-cell bg-gray-50"
                ></th> -->
              </tr>
            </thead>
            <tbody class="flex-1 bg-white divide-y divide-gray-300 sm:flex-none print:flex-none">
              <tr
                class="flex flex-col mb-2 flex-no wrap sm:table-row print:table-row sm:mb-0"
                v-for="shipment in items"
                :key="shipment._id"
              >
                <td class="">
                  <div class="flex self-start justify-center w-10">
                    <input
                      v-model="selected"
                      :id="'shipment_' + shipment._id"
                      :value="shipment._id"
                      @change="selectAll = false"
                      type="checkbox"
                      checked
                      class="w-4 h-4 text-indigo-600 border-gray-300 rounded focus:ring-indigo-500"
                    />
                  </div>
                </td>
                <td class="flex py-3 overflow-hidden whitespace-no-wrap pe-6">
                  <div class="">
                    <!-- <w-food-shipment-address :value="shipment.shipping" /> -->
                    <div v-if="shipment.shipping" class="w-24 md:w-auto print:w-auto">
                      <span class="text-xs italic text-gray-500"
                        >#{{ shipment.order.orderId }}</span
                      >
                      <span
                        class="px-1 ml-1 text-xs text-white bg-red-500 rounded"
                        v-if="shipment.prelaunch"
                        >Prelanzamiento</span
                      >
                      <div
                        v-if="shipment.shipping"
                        class="text-xs leading-5 text-gray-900 md:text-sm"
                      >
                        {{ shipment.shipping.name }}
                        {{ shipment.shipping.lastName }}
                      </div>
                      <div
                        v-else-if="shipment.shipping"
                        class="text-xs leading-5 text-gray-900 md:text-sm"
                      >
                        {{ shipment.shipping.name }}
                        {{ shipment.shipping.lastName }}
                      </div>
                      <div
                        v-else
                        class="text-xs italic font-medium leading-5 text-gray-400 md:text-sm"
                      >
                        Sin Nombre
                      </div>
                      <div class="text-xs leading-5 text-gray-500 md:text-sm">
                        {{ shipment.shipping.email }}
                      </div>
                      <div
                        v-if="shipment.shipping"
                        class="flex items-center text-xs leading-5 text-gray-500 md:text-sm"
                      >
                        <div>
                          {{ parsePhone(shipment.shipping.phone) }}
                        </div>
                        <a
                          v-bind:href="parsePhone(shipment.shipping.phone) | whatsapp"
                          target="_blank"
                          ><svg
                            style="color: #25d366"
                            class="w-3 h-3 ml-1 cursor-pointer"
                            fill="currentColor"
                            role="img"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <title>WhatsApp icon</title>
                            <path
                              d="M17.472 14.382c-.297-.149-1.758-.867-2.03-.967-.273-.099-.471-.148-.67.15-.197.297-.767.966-.94 1.164-.173.199-.347.223-.644.075-.297-.15-1.255-.463-2.39-1.475-.883-.788-1.48-1.761-1.653-2.059-.173-.297-.018-.458.13-.606.134-.133.298-.347.446-.52.149-.174.198-.298.298-.497.099-.198.05-.371-.025-.52-.075-.149-.669-1.612-.916-2.207-.242-.579-.487-.5-.669-.51-.173-.008-.371-.01-.57-.01-.198 0-.52.074-.792.372-.272.297-1.04 1.016-1.04 2.479 0 1.462 1.065 2.875 1.213 3.074.149.198 2.096 3.2 5.077 4.487.709.306 1.262.489 1.694.625.712.227 1.36.195 1.871.118.571-.085 1.758-.719 2.006-1.413.248-.694.248-1.289.173-1.413-.074-.124-.272-.198-.57-.347m-5.421 7.403h-.004a9.87 9.87 0 01-5.031-1.378l-.361-.214-3.741.982.998-3.648-.235-.374a9.86 9.86 0 01-1.51-5.26c.001-5.45 4.436-9.884 9.888-9.884 2.64 0 5.122 1.03 6.988 2.898a9.825 9.825 0 012.893 6.994c-.003 5.45-4.437 9.884-9.885 9.884m8.413-18.297A11.815 11.815 0 0012.05 0C5.495 0 .16 5.335.157 11.892c0 2.096.547 4.142 1.588 5.945L.057 24l6.305-1.654a11.882 11.882 0 005.683 1.448h.005c6.554 0 11.89-5.335 11.893-11.893a11.821 11.821 0 00-3.48-8.413Z"
                            /></svg
                        ></a>
                      </div>
                    </div>
                    <div class="text-xs italic text-gray-500">
                      hace
                      {{ shipment.foodUpdated || shipment.createdAt | moment("from", true) }}
                    </div>
                  </div>
                </td>
                <td class="px-6 whitespace-no-wrap md:py-3 min-w-15">
                  <div class="text-xs min-w-15">
                    <div class="" v-for="(it, itIndex) in shipment.order.cart" :key="itIndex">
                      <div v-if="shipment.order.method === 'mercadopago'">
                        {{ it.qty }} x {{ it.sku || it.name }} (${{ it.price.ars * it.qty }})
                      </div>
                      <div v-else>
                        {{ it.qty }} x {{ it.sku || it.name }} (${{ it.price.usd * it.qty }})
                      </div>
                      <w-item-variant :item="it" />
                    </div>
                    <div :key="i" v-for="(it, i) in procesarApuestas(shipment.order.cart)">
                      1 x {{ it }} <i class="text-xs text-gray-500">- apuesta</i>
                    </div>
                    <w-shipment-custom-values :value="shipment.customValues" />
                    <w-bonos-item :value="shipment._id" />
                    <div :key="i" v-for="(it, i) in procesarCopaAmerica(shipment.order.cart)">
                      <div class="mt-1 pt-1 border-t border-gray-300" v-html="it"></div>
                    </div>
                  </div>
                  <!-- <div class="text-xs">
                    <div class="" v-for="(it, itIndex) in shipment.items" :key="itIndex">
                      <div>{{ it.qty }} x {{ it.sku || it.name }}</div>
                      <w-item-variant :item="it" />
                    </div>
                    <w-shipment-custom-values :value="shipment.customValues" />
                    <w-bonos-item :value="shipment._id" />
                  </div> -->
                </td>
                <td class="hidden px-6 py-4 text-sm whitespace-no-wrap md:table-cell">
                  <div>
                    <div v-if="shipment.shippingMethod" class="font-semibold">
                      {{ shipment.shippingMethod.company || shipment.shippingMethod.name }}
                    </div>
                    <w-shipment-status
                      v-if="shipment.trackingCode || shipment.printed"
                      :status="shipment.lastStatus"
                      :events="shipment.events"
                      :printed="shipment.printed"
                      :date="shipment.statusDate"
                    />
                    <div
                      v-if="
                        shipment.shipping &&
                        shipment.shippingMethod.company != 'Retiro en la tienda'
                      "
                      class="text-xs"
                    >
                      <div>
                        {{
                          [
                            shipment.shipping.address +
                              " " +
                              (shipment.shipping.addressNumber
                                ? shipment.shipping.addressNumber
                                : ""),
                            shipment.shipping.details,
                            shipment.shipping.city,
                          ]
                            .filter((it) => it)
                            .join(", ")
                        }},
                      </div>
                      <div>
                        {{ shipment.shipping.state }},
                        {{ shipment.shipping.country }}
                      </div>
                    </div>

                    <!-- Delivered -->
                    <div v-if="shipment.delivered" class="text-xs text-green-500">
                      <div class="font-semibold">Entregado a {{ shipment.deliveredData.to }}</div>
                      <div class="font-base italic">Por {{ shipment.deliveredData.by }}</div>
                    </div>
                  </div>
                  <w-detalle-apuesta :shipment="shipment.order" />
                </td>
                <td class="items-start text-xs whitespace-no-wrap">
                  <w-order-payment-details :order="shipment.order" />
                  <w-discounts-detail :data="shipment.order.discounts"></w-discounts-detail>
                </td>
                <!-- <td class="hidden px-6 py-4 whitespace-no-wrap md:table-cell">
                  <span
                    v-if="shipment.archive"
                    class="inline-flex px-2 text-xs font-medium leading-5 text-green-100 bg-green-500 rounded-full"
                  >
                    Archivada
                  </span>
                  <span
                    v-else
                    class="inline-flex px-2 text-xs font-medium leading-5 text-orange-100 bg-orange-400 rounded-full"
                  >
                    Pendiente
                  </span>
                  <div class="text-xs italic text-gray-500">
                    hace
                    {{
                      shipment.foodUpdated ||
                      shipment.createdAt | moment("from", true)
                    }}
                  </div>
                </td> -->
                <td
                  class="px-6 py-4 text-sm font-medium leading-5 text-right whitespace-no-wrap md:w-32"
                >
                  <div class="relative flex gap-2 print:hidden">
                    <!-- Andreani -->
                    <button
                      @click="$emit('downloadEtiqueta', shipment._id)"
                      class="flex items-center text-xs font-semibold text-gray-500 transition duration-150 ease-in-out"
                      v-tooltip="'Etiqueta Impresa'"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-4 h-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                        />
                      </svg>
                    </button>

                    <button
                      v-if="shipment.isAndreani && shipment._id && !shipment.pendingAndreani"
                      @click="$emit('downloadEtiqueta', shipment._id)"
                      class="flex items-center text-xs font-semibold text-indigo-500 transition duration-150 ease-in-out"
                      v-tooltip="'Descargar etiqueta'"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-4 h-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                        />
                      </svg>
                    </button>
                    <a
                      v-if="
                        shipment.trackingCode &&
                        shipment.shippingMethodId === '61956f84f0ab2b002e496733'
                      "
                      :href="`https://www.andreani.com/#!/informacionEnvio/${shipment.trackingCode}`"
                      class="flex items-center text-xs font-semibold text-indigo-500 transition duration-150 ease-in-out cursor-pointer"
                      target="_blank"
                      v-tooltip="'Rastrear envío'"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-4 h-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
                        />
                      </svg>
                    </a>
                    <!-- Cierre Andreani -->

                    <!-- DHL -->
                    <button
                      v-if="shipment.isDHL && shipment.printed"
                      @click="$emit('downloadEtiqueta', shipment._id, 'DHL')"
                      class="flex items-center text-xs font-semibold text-gray-500 transition duration-150 ease-in-out"
                      v-tooltip="'Etiqueta Impresa'"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-4 h-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M17 17h2a2 2 0 002-2v-4a2 2 0 00-2-2H5a2 2 0 00-2 2v4a2 2 0 002 2h2m2 4h6a2 2 0 002-2v-4a2 2 0 00-2-2H9a2 2 0 00-2 2v4a2 2 0 002 2zm8-12V5a2 2 0 00-2-2H9a2 2 0 00-2 2v4h10z"
                        />
                      </svg>
                    </button>

                    <button
                      v-if="shipment.isDHL && shipment._id"
                      @click="$emit('downloadEtiqueta', shipment._id, 'DHL')"
                      class="flex items-center text-xs font-semibold text-indigo-500 transition duration-150 ease-in-out"
                      v-tooltip="'Descargar etiqueta'"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-4 h-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                        />
                      </svg>
                    </button>
                    <a
                      v-if="shipment.dhlAWB && shipment.trackingCode"
                      :href="`https://www.dhl.com/ar-es/home/tracking/tracking-express.html?submit=1&tracking-id=${shipment.trackingCode}`"
                      class="flex items-center text-xs font-semibold text-indigo-500 transition duration-150 ease-in-out cursor-pointer"
                      target="_blank"
                      v-tooltip="'Rastrear envío'"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-4 h-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
                        />
                      </svg>
                    </a>
                    <a
                      v-if="
                        shipment.shippingMethodId === '64c1aa6b60e18d0011235982' &&
                        shipment.trackingCode
                      "
                      :href="shipment.trackingCode"
                      class="flex items-center text-xs font-semibold text-indigo-500 transition duration-150 ease-in-out cursor-pointer"
                      target="_blank"
                      v-tooltip="'Rastrear envío'"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-4 h-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M7 7h.01M7 3h5c.512 0 1.024.195 1.414.586l7 7a2 2 0 010 2.828l-7 7a2 2 0 01-2.828 0l-7-7A1.994 1.994 0 013 12V7a4 4 0 014-4z"
                        />
                      </svg>
                    </a>
                    <!-- Cierre DHL -->

                    <!-- Router Link para Editar elemento, con icono SVG de Editar -->
                    <router-link
                      :to="`/shipments/${shipment.shippingId}/edit`"
                      class="flex items-center text-xs font-semibold text-indigo-500 transition duration-150 ease-in-out"
                      v-tooltip="'Editar'"
                    >
                      <!-- Hero Icon Edit -->
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="w-4 h-4"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                        />
                      </svg>
                    </router-link>

                    <button
                      v-tooltip="'Archivar'"
                      v-if="!shipment.archive"
                      @click="archive(shipment, true)"
                      class-old="flex items-center px-2 py-1 text-xs font-semibold text-white transition duration-150 ease-in-out bg-green-500 rounded hover:bg-green-700"
                      class="flex items-center text-xs font-semibold text-red-500 transition duration-150 ease-in-out"
                    >
                      <svg
                        v-if="busy == shipment._id"
                        class="w-3 h-3 mr-1 animate-spin"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          class="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          stroke-width="4"
                        ></circle>
                        <path
                          class="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      <svg
                        v-else
                        xmlns="http://www.w3.org/2000/svg"
                        class="w-4 h-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M5 8h14M5 8a2 2 0 110-4h14a2 2 0 110 4M5 8v10a2 2 0 002 2h10a2 2 0 002-2V8m-9 4h4"
                        />
                      </svg>
                      <!-- <span>Archivar</span> -->
                    </button>
                    <button
                      v-if="shipment.archive"
                      @click="archive(shipment, false)"
                      class="flex items-center px-2 py-1 text-xs font-semibold text-white transition duration-150 ease-in-out bg-red-500 rounded hover:bg-red-700"
                    >
                      <svg
                        v-if="busy == shipment._id"
                        class="w-3 h-3 mr-1 animate-spin"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          class="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          stroke-width="4"
                        ></circle>
                        <path
                          class="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      <span>Deshacer</span>
                    </button>
                  </div>
                </td>
              </tr>

              <!-- More rows... -->
            </tbody>
          </table>
          <!-- <div
            v-if="totalResults > limit"
            @click="nextPage()"
            class="items-center p-3 text-sm font-medium text-center text-indigo-300 cursor-pointer glex hover:text-indigo-500"
          >
            Cargar 20 más de {{ totalResults - limit }} resultados.
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    hide: {
      type: Array,
      default: () => [],
    },
    value: {
      type: String,
      default: () => {
        return null;
      },
    },
    totalResults: {
      type: Number,
      default: 0,
    },
    eventPrice: {
      type: Number,
      default: 0,
    },
    patchPending: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selected: [],
      selectAll: false,
      selectAllAll: false,
      showImage: null,
      upload: null,
      limit: 20,
      busy: false,
    };
  },
  watch: {
    value(newValue) {
      if (newValue === "clear") {
        this.selectAll = false;
        this.selectAllAll = false;
        this.selected = [];
        this.$emit("input", null);
      }
    },
  },
  methods: {
    procesarApuestas(cart) {
      const apuesta = cart.find((it) => it.apuesta);
      if (!apuesta) return [];

      if (apuesta.sku === "COPA-AMERICA") return [];

      const apuestasArray = Object.values(apuesta.apuesta);
      return apuestasArray.slice(0, 3);
    },
    procesarCopaAmerica(items) {
      return items
        .filter((it) => it.sku === "COPA-AMERICA")
        .map((producto) => {
          console.log("Apuesta", producto);
          // local: 0
          // visitante: 0
          // producto: PIB
          // producto_resultado: REN
          // producto_resultado_exacto: OR4
          // fechaPartido:
          // partido: { "local": "Perú", "visitante": "Chile" }

          let texto = `${producto.apuesta.partido.local} ${producto.apuesta.local} vs ${producto.apuesta.partido.visitante} ${producto.apuesta.visitante}`;

          // Libro base
          texto += `<BR/>Producto base: ${producto.apuesta.producto}`;
          texto += `<BR/>Acertando el resultado: ${producto.apuesta.producto_resultado}`;
          texto += `<BR/>Acertando el resultado exacto: ${producto.apuesta.producto_resultado_exacto}`;
          return texto;
        });
    },
    parsePhone(phone) {
      if (!phone) return "";
      if (typeof phone == "string") {
        return "+" + phone.replace(/[^0-9]/g, "");
      }
      if (!phone.formatInternational) return "";
      return phone.formatInternational;
    },
    toggleAll() {
      if (this.selectAll) this.selected = this.items.map((s) => s._id);
      else this.selected = [];
    },
    async archive(shipment, status) {
      console.log("archive");
      this.busy = shipment._id;
      await this.$store.dispatch("shipments/patch", [shipment._id, { archive: status }, {}]);
      this.$emit("reload");
      this.busy = false;
    },
    nextPage() {
      this.limit += 20;
      this.$emit("nextPage", this.limit);
    },
  },
};
</script>

<style lang="css" scoped>
@media (min-width: 640px) {
  table {
    display: inline-table !important;
  }

  thead tr:not(:first-child) {
    display: none;
  }
}

td:not(:last-child) {
  bshipment-bottom: 0;
}

th:not(:last-child) {
  bshipment-bottom: 2px solid rgba(0, 0, 0, 0.1);
}
.button-w-action {
  min-width: 150px;
}
.min-w-15 {
  min-width: 15rem;
}
</style>
