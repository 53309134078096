<template>
  <span
    :class="message.class"
    v-tooltip="{
      placement: 'top',
      trigger: 'hover',
      content: parsedEvents,
      html: true,
    }"
    class="text-xs cursor-pointer inline-flex items-center font-semibold px-1.5 py-0.5 rounded"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="w-4 h-4 font-thin"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        d="M9 17a2 2 0 11-4 0 2 2 0 014 0zM19 17a2 2 0 11-4 0 2 2 0 014 0z"
      />
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        stroke-width="2"
        d="M13 16V6a1 1 0 00-1-1H4a1 1 0 00-1 1v10a1 1 0 001 1h1m8-1a1 1 0 01-1 1H9m4-1V8a1 1 0 011-1h2.586a1 1 0 01.707.293l3.414 3.414a1 1 0 01.293.707V16a1 1 0 01-1 1h-1m-6-1a1 1 0 001 1h1M5 17a2 2 0 104 0m-4 0a2 2 0 114 0m6 0a2 2 0 104 0m-4 0a2 2 0 114 0"
      />
    </svg>
    <span class="ml-1">{{ message.status }} {{ dateText }}</span>
  </span>
</template>
<script>
export default {
  props: {
    status: {
      type: String,
      default: null,
    },
    date: {
      type: String,
    },
    printed: {
      type: Boolean,
      default: false,
    },
    events: {
      type: Array,
      default: null,
    },
  },
  computed: {
    parsedEvents() {
      if (!this.events || !this.events.length) {
        return '<div class="text-xs">Sin eventos aún</div>';
      }
      return (
        '<div class="text-xs">' +
        this.events
          .map((event) => {
            const date = this.$moment(event.Fecha).format("DD/MM/YYYY hh:mm");
            const status = event.detalle || event.Estado;
            return `${date}: ${status}`;
          })
          .reverse()
          .join("<br>") +
        "</div>"
      );
    },
    message() {
      console.log("this.status", this.status);
      if (this.status === "Entregado") {
        return {
          status: this.status,
          class:
            "bg-green-100 text-green-800 dark:bg-green-200 dark:text-green-800",
        };
      } else if (this.status === "En camino") {
        return {
          status: this.status,
          class:
            "bg-green-100 text-green-800 dark:bg-green-200 dark:text-green-800",
        };
      } else if (this.status == "En viaje") {
        return {
          status: this.status,
          class:
            "bg-green-100 text-green-800 dark:bg-green-200 dark:text-green-800",
        };
      } else if (this.status === "Pendiente de ingreso") {
        return {
          status: this.status,
          class: "bg-red-100 text-red-800 dark:bg-red-200 dark:text-red-800",
        };
      } else if (this.status === "Pendiente de Generación") {
        return {
          status: this.status,
          class: "bg-red-100 text-red-800 dark:bg-red-200 dark:text-red-800",
        };
      } else if (this.status) {
        return {
          status: this.status,
          class:
            "bg-blue-100 text-blue-800 dark:bg-blue-200 dark:text-blue-800",
        };
      } else if (!this.printed) {
        return {
          status: "Sin imprimir",
          class: "bg-red-100 text-red-800 dark:bg-red-200 dark:text-red-800",
        };
      } else {
        return {
          status: "Sin estado",
          class: "bg-red-100 text-red-800 dark:bg-red-200 dark:text-red-800",
        };
      }
    },
    dateText() {
      if (this.date) {
        return this.$moment(this.date).format("DD/MM/YYYY HH:mm");
      } else {
        return null;
      }
    },
  },
};
</script>
