<template>
  <div class="flex items-center justify-end mr-2 w-full">
    <label
      for="input"
      :class="{ 'text-red-500': false }"
      class="block text-sm font-medium leading-5 text-gray-700"
      >{{ labelDay }}</label
    >
    <v-date-picker
      timezone="America/Argentina/Buenos_Aires"
      v-model="date"
      is-range
    >
      <template v-slot="{ inputValue, inputEvents }">
        <div class="flex items-center justify-end me-2">
          <input
            :value="inputValue.start"
            v-on="inputEvents.start"
            class="
              w-2/5
              px-2
              py-1
              border
              rounded
              border-base-200
              focus:outline-none focus:border-base-300
              text-center
            "
          />

          <span class="w-8">
            <svg
              class="w-4 h-4 mx-2 text-base-700"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M14 5l7 7m0 0l-7 7m7-7H3"
              />
            </svg>
          </span>
          <input
            :value="inputValue.end"
            v-on="inputEvents.end"
            class="
              w-2/5
              px-2
              py-1
              border
              rounded
              focus:outline-none
              border-base-200
              focus:border-base-300
              text-center
            "
          />
        </div>
      </template>
    </v-date-picker>

    <!-- <v-date-picker
            is-range
            :masks="masks"
            :value="value"
            @input="$emit('input', $event)"
            is24hr
          >
            <template v-slot="{ inputValue, inputEvents }">
              {{ inputValue }}
              <input
                class="px-2 py-1 border rounded focus:outline-none focus:border-blue-300"
                :value="inputValue.start + ' a ' + inputValue.end"
                v-on="inputEvents"
              />
            </template>
          </v-date-picker> -->
  </div>
</template>

<script>
import moment from "moment";
export default {
  props: [
    "placeholder",
    "label-hour",
    "label-day",
    "helper",
    "value",
    "duration",
    "initValue",
  ],
  data() {
    return {
      show: false,
      masks: {
        input: "DD-MM-YYYY h:mm A",
      },
      date: {
        start: moment().subtract(7, "days").toDate(),
        end: moment().endOf("day").toDate(),
      },
      // hour: null,
      // error: false,
      // day: null,
      // durationValue: 0,
    };
  },
  computed: {
    // eventDate() {
    //   if (!this.hour) return null;
    //   const date = this.$moment(this.day);
    //   date.hour(this.hour.HH);
    //   date.minute(this.hour.mm);
    //   date.second(0);
    //   return date.toDate();
    // },
  },
  watch: {
    date: {
      // immediate: true,
      // deep: true,
      handler(newDate) {
        console.log("DATE CHANGED");
        this.$emit("input", newDate);
      },
    },
  },
  mounted() {
    console.log("init value", this.value);
    if (this.value) {
      this.date = this.value;
    }
    // let localDate = this.$moment(this.value).local();
    // this.initValue = localDate.toDate();

    // let hour = this.$moment();
    // if (this.value) hour = this.$moment(this.value);
    // this.day = hour.toDate();
    // this.hour = {
    //   HH: hour.format("HH"),
    //   mm: hour.format("mm"),
    // };
    // this.$emit("input", this.$moment(this.eventDate));
    // this.durationValue = this.duration;
  },
  methods: {
    // changeDay(date) {
    //   console.log("changeDay", this.hour);
    //   const value = this.$moment(date);
    //   value.hour(this.hour.HH);
    //   value.minute(this.hour.mm);
    //   this.day = value.toDate();
    //   console.log("this.day", this.day);
    // },
    // changeHour(value) {
    //   this.hour = value;
    // },
  },
};
</script>

<style lang="scss">
.vue__time-picker input {
  @apply block;
  @apply px-2;
  @apply py-1;
  @apply mt-1;
  @apply border;
  @apply rounded;
  @apply ring-1 ring-black ring-opacity-5;
  @apply items-center;
  @apply text-sm;
}
.vc-appearance-none {
  @apply block;
  @apply px-2;
  @apply py-1;
  @apply mt-1;
  @apply border;
  @apply rounded;
  @apply ring-1 ring-black ring-opacity-5;
  @apply items-center;
  @apply text-sm;
}
</style>
