<template>
  <div v-if="variant" class="flex">
    
    <div
      v-for="(item, vIndex) in variant"
      :key="vIndex"
      class="flex max-w-sm text-xs"
    >
    <w-item-variant-line :item="item"/>
    </div>
  </div>
</template>

<script>
import { makeFindMixin } from "feathers-vuex";
export default {
  props: {
    item: {
      type: [Array, Object],
      default: null,
    },
  },
  mixins: [makeFindMixin({ service: "products" })],
  computed: {
    productsParams() {
      return {
        query: {
          $select: ["name", "sku", "_id"],
        },
      };
    },
    variant() {
      return this.item.variant;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>