<template>
<!-- This example requires Tailwind CSS v2.0+ -->
<div class="bg-white rounded mt-2 px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
  <div class="flex-1 flex justify-between sm:hidden">
    <a v-if="hasPrev()" href="#" class="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-200">
      Anterior
    </a>
    <button v-if="hasNext()" @click="$emit('changePage', currentPage + 1)" class="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-200">
      Siguiente
    </button>
  </div>
  <div class="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
    <div>
      <p class="text-sm text-gray-700">
        Mostrando
        <span class="font-medium">{{from || 0}}</span>
        a
        <span class="font-medium">{{to}}</span>
        de
        <span class="font-medium">{{totalResults}}</span>
        pedidos
      </p>
    </div>
    <div>
      <nav class="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
        <a v-if="hasPrev()" @click.prevent="changePage(prevPage)" href="#" class="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-200">
          <span class="sr-only">Anterior</span>
          <!-- Heroicon name: solid/chevron-left -->
          <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z" clip-rule="evenodd" />
          </svg>
        </a>
        <!-- Current: "z-10 bg-indigo-50 border-indigo-500 text-indigo-600", Default: "bg-white border-gray-300 text-gray-500 hover:bg-gray-200" -->
        <a v-for="page in pages" :key="page" @click="changePage(page)" href="#" :class="{'z-10 bg-indigo-50 border-indigo-500 text-indigo-60': page === currentPage}"  class="bg-white border-gray-300 text-gray-500 hover:bg-gray-200 relative inline-flex items-center px-4 py-2 border text-sm font-medium">
          {{page}}
        </a>
        <a v-if="hasNext()" @click.prevent="changePage(nextPage)" href="#" class="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-200">
          <span class="sr-only">Siguiente</span>
          <!-- Heroicon name: solid/chevron-right -->
          <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
            <path fill-rule="evenodd" d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z" clip-rule="evenodd" />
          </svg>
        </a>
      </nav>
    </div>
  </div>
</div>
</template>
<script>
export default {
  name: 'WPagination',
  props: {
    totalResults: {
      type: Number,
      default: 0
    },
    value: {
      type: Number,
      default: 1
    },
    resultsPerPage: {
      type: Number,
      default: 12
    },
    pageRange: {
      type: Number,
      default: 2
    },
  },
  methods: {
    hasFirst() {
      return this.rangeStart !== 1
    },
    hasLast() {
      return this.rangeEnd < this.totalPages
    },
    hasPrev() {
      return this.currentPage > 1
    },
    hasNext() {
      return this.currentPage < this.totalPages
    },
    changePage(page) {
      if (page > 0 && page <= this.totalPages) {
        this.$emit('pageChanged', page)
      }
    },

  },
  computed: {
    currentPage(){
      return this.value;
    },
    from() {return (this.currentPage - 1) * this.resultsPerPage + 1},
    to(){
      if(this.totalResults < this.resultsPerPage * this.currentPage){
        return this.totalResults;
      }
      return (this.currentPage) * this.resultsPerPage
    },
    pages() {
      var pages = []
      for (var i = this.rangeStart; i <= this.rangeEnd; i++) {
        pages.push(i)
      }
      return pages
    },
    rangeStart() {
      var start = this.currentPage - this.pageRange
      return (start > 0) ? start : 1
    },
    rangeEnd() {
      var end = this.currentPage + this.pageRange
      return (end < this.totalPages) ? end : this.totalPages
    },
    totalPages() {
      return Math.ceil(this.totalResults / this.resultsPerPage)
    },
    nextPage() {
      return this.currentPage + 1
    },
    prevPage() {
      return this.currentPage - 1
    }
  }
}
</script>
