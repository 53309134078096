import { render, staticRenderFns } from "./index.vue?vue&type=template&id=8a017a68&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
import style0 from "./index.vue?vue&type=style&index=0&id=8a017a68&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8a017a68",
  null
  
)

export default component.exports

/* vue-automatic-import-loader */
import autoInstallComponents from "!/workspace/node_modules/vue-automatic-import-loader/lib/runtime/autoInstallComponents.js"
import WAndreaniConfirm from '@/components/WAndreaniConfirm.vue'
import WDateRange from '@/components/WDateRange.vue'
import WLoading from '@/components/WLoading.vue'
import WPagination from '@/components/WPagination.vue'
import WSelect from '@/components/WSelect.vue'
import WShipments from '@/components/WShipments.vue'
import WShippingMethodSelect from '@/components/WShippingMethodSelect.vue'
import WTopBar from '@/components/WTopBar.vue'
autoInstallComponents(component, {WAndreaniConfirm,WDateRange,WLoading,WPagination,WSelect,WShipments,WShippingMethodSelect,WTopBar})
