<template>
<div>
  <div v-for="(value,label, index) in value" :key="index">
    <div v-tooltip="label">1 x {{value}}</div>
  </div>
</div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Array, Object],
      default: null,
    },
  },
  computed: {
  },
}
</script>

<style lang="scss" scoped></style>
