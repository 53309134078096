import { render, staticRenderFns } from "./WItemVariant.vue?vue&type=template&id=a7ed4a28&scoped=true&"
import script from "./WItemVariant.vue?vue&type=script&lang=js&"
export * from "./WItemVariant.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a7ed4a28",
  null
  
)

export default component.exports

/* vue-automatic-import-loader */
import autoInstallComponents from "!/workspace/node_modules/vue-automatic-import-loader/lib/runtime/autoInstallComponents.js"
import WItemVariantLine from '@/components/WItemVariantLine.vue'
autoInstallComponents(component, {WItemVariantLine})
